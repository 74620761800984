import { Box, Button, Flex, Input, Spacer, Text } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import FileUpload from '../../_components/FileUpload'
import TextEditor from '../../_components/TextEditor'

function TrainingEdit() {
    const [, setTopRightButton] = useOutletContext()

    const [titleError, setTitleError] = useState('')
    const [contentError, setContentError] = useState('')
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    useEffect(() => {
        setTopRightButton(null)
    }, [])

    return (
        <Fragment>
            <Box bg='gray.50' color='gray.700' p={4}>
                <Box>
                    {/* <Text fontWeight={500}>Upload Gambar</Text>
                    <FileUpload accept='image/*' fileHandler={useState('')} width='100%' />
                </Box>
                <Box mt={4}> */}
                    <Text fontWeight={500}>Judul</Text>
                    <Input isInvalid={titleError} disabled={loading} placeholder='Judul' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                    {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                </Box>
                <Box mt={8} width='99.5%' overflow='auto' >
                    <TextEditor content={content} setContent={setContent} disabled={loading} setParentLoading={setLoading} />
                    {contentError ? <Text fontSize={14} color='red.500'>{contentError}</Text> : ''}
                </Box>
            </Box>
            <Flex>
                <Spacer />
                <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={() => { }}>Simpan</Button>
            </Flex>
        </Fragment>

    )
}

export default TrainingEdit