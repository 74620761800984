import { Box, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

function StatsBox({ bg, title, value, isLoading = false }) {
    return (
        <Box rounded={4} bg='gray.50' width='23%'>
            <Box bg={bg} p={4} roundedTopLeft={4} roundedTopRight={4} color='white' fontWeight={700}>
                {title}
            </Box>
            <Text
                pl={4} mt={12} fontSize={12} color='gray.900'>
                Total
            </Text>
            {
                !isLoading ?
                    <Text pl={4} pb={4} fontSize={18} fontWeight={700} color='gray.900'>
                        {value}
                    </Text> : <Box pl={4} pb={4} ><Spinner size='sm' /></Box>
            }
        </Box>
    )
}

export default StatsBox