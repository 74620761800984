export const LIST_SIDEBAR = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        child: [],
    },
    {
        name: 'Beranda',
        link: '/beranda',
        child: [
            {
                name: 'Banner',
                link: '/beranda/banner',
                child: [],
            },
            {
                name: 'Ire Update',
                link: '/beranda/ire-update',
                child: [],
            },
            {
                name: 'Tentang Ire',
                link: '/beranda/ire',
                child: [
                    {
                        name: 'Tentang',
                        link: '/beranda/ire/tentang/edit',
                    },
                    {
                        name: 'Organisasi',
                        link: '/beranda/ire/organisasi',
                    },
                ],
            },
            {
                name: 'Ire Next Gen',
                link: '/beranda/next-gen/edit',
                child: [],
            },
            {
                name: 'Publikasi',
                link: '/beranda/publikasi',
                child: [],
            },
            {
                name: 'Galeri Foto',
                link: '/beranda/foto',
                child: [],
            },
            {
                name: 'Galeri Video',
                link: '/beranda/video',
                child: [],
            },
            {
                name: 'Training Center',
                link: '/halaman/training-center/post',
                child: [],
            },
            {
                name: 'Perpustakaan',
                link: '/beranda/perpustakaan/edit',
                child: [],
            },
            {
                name: 'Program',
                link: '/halaman/program/post',
                child: [],
            },
        ],
    },
    {
        name: 'Halaman',
        link: '/halaman',
        child: [
            {
                name: 'Semua Halaman',
                link: '/halaman/',
                child: [],
            },
            {
                name: 'Tambah Halaman',
                link: '/halaman/add',
                child: [],
            }
        ],
    },
    {
        name: 'Komentar',
        link: '/komentar',
        child: [],
    },
    {
        name: 'Download',
        link: '/download',
        child: [],
    },


]