import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdLibraryBooks } from 'react-icons/md'

function PageTitle({ title, mt = 0, mb = 0, showIcon = true }) {
    return (
        <Flex alignItems='center' mt={mt} mb={mb}>
            {showIcon ?
                <Icon as={MdLibraryBooks} mr={2} /> : ''
            }
            <Text fontWeight={600} color='gray.700'>{title} </Text>
        </Flex>
    )
}

export default PageTitle