import { Box, Button, Flex, Input, Spacer, Text, useToast, Image, Center } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from '../../../../../context/AuthContext'
import { createdToast, errorAxiosToast, updatedToast } from '../../../../../lib/const/toast'
import { addTeam, getTeam, updateTeam } from '../../../../../services/user.services'
import FileUpload from '../../../_components/FileUpload'
import PageTitle from '../../../_components/PageTitle'

function OrganisasiAdd({ isEdit = false }) {
    const [loading, setLoading] = useState(false)
    const [auth,] = useContext(AuthContextState)
    const [, setTopRightButton] = useOutletContext()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [position, setPosition] = useState('')
    const [positionError, setPositionError] = useState('')
    const [imageUpload, setImageUpload] = useState()
    const [imageUploaded, setImageUploaded] = useState([])
    const [imageUploadError, setImageUploadError] = useState()
    const navigate = useNavigate()
    const toast = useToast()
    const param = useParams()

    const init = () => {
        let id = param.id
        if (!id) {
            return
        }
        setLoading(true)
        getTeam(auth, id).then(data => {
            console.log(data)
            setName(data.data.data.name)
            setPosition(data.data.data.position)
            setImageUploaded([data.data.data.avatar])
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setLoading(false)
        })
    }

    const uploadImageHandler = (e) => {
        setImageUpload(e)
        console.log(e)
    }

    const storeOrganizationHandler = () => {
        setNameError('')
        setPositionError('')
        setImageUploadError('')

        if (name === ' ' || name === '') {
            setNameError('Nama tidak boleh kosong')
            return
        }

        if (position === ' ' || position === '') {
            setPositionError('Jabatan tidak boleh kosong')
            return
        }

        if (imageUpload === null) {
            setImageUploadError('Foto tidak boleh kosong')
            return
        }

        setLoading(true)

        addTeam(auth, { name, position, image: imageUpload }).then(data => {
            console.log(data)
            setLoading(false)
            navigate('/beranda/ire/organisasi')
            toast(createdToast)
        }).catch(e => {
            console.log(e)
            toast(errorAxiosToast(e))
            setLoading(false)
        })
    }

    const updateOrganizationHandler = () => {
        setNameError('')
        setPositionError('')
        setImageUploadError('')

        if (name === ' ' || name === '') {
            setNameError('Nama tidak boleh kosong')
            return
        }

        if (position === ' ' || position === '') {
            setPositionError('Jabatan tidak boleh kosong')
            return
        }

        if (imageUpload === null) {
            setImageUploadError('Foto tidak boleh kosong')
            return
        }

        setLoading(true)
        updateTeam(auth, { name, position, image: imageUpload, id: param.id }).then(data => {
            console.log(data)
            setLoading(false)
            navigate('/beranda/ire/organisasi')
            toast(updatedToast)
        }).catch(e => {
            console.log(e)
            toast(errorAxiosToast(e))
            setLoading(false)
        })
    }

    useEffect(() => {
        setTopRightButton(null)

        init()
        // console.log(getDateNow())
    }, [])
    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title='Organisasi' showIcon={false} mt={0} mb={0} />
            </Box>
            <Box bg='gray.50' p={4} color='gray.700'>
                <Box>
                    <Text fontWeight={500}>Nama</Text>
                    <Input isInvalid={nameError} disabled={loading} placeholder='Nama' bg='white' my={4} value={name} onChange={(e) => setName(e.target.value)} />
                    {nameError ? <Text fontSize={14} color='red.500'>{nameError}</Text> : ''}
                </Box>
                {
                    !imageUpload && imageUploaded.length > 0 ?
                        <Flex direction='column' justifyContent='flex-start'>
                            <Box boxSize='sm' >
                                <Text fontWeight={500}>Foto yang telah diupload</Text>
                                <Image my={4} src={imageUploaded} objectFit='contain' />
                            </Box>
                        </Flex> : ''
                }
                <Box mt={2}>
                    <Text fontWeight={500}>Upload Foto</Text>

                    <FileUpload accept='image/jpeg,image/png' fileHandler={[imageUpload, setImageUpload]} callbackAfterChange={uploadImageHandler} />
                </Box>
                <Box mt={6}>
                    <Text fontWeight={500}>Jabatan</Text>
                    <Input isInvalid={positionError} disabled={loading} placeholder='Jabatan' bg='white' my={4} value={position} onChange={(e) => setPosition(e.target.value)} />
                    {positionError ? <Text fontSize={14} color='red.500'>{positionError}</Text> : ''}
                </Box>
            </Box>
            <Flex my={4}>
                <Spacer />
                <Button colorScheme='teal' onClick={isEdit ? updateOrganizationHandler : storeOrganizationHandler} isLoading={loading}>Simpan</Button>
            </Flex>
        </Fragment>
    )
}

export default OrganisasiAdd