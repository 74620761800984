import { Center, CircularProgress } from '@chakra-ui/react';


import React, { useEffect, useRef, useState } from 'react'
import { BASE_API_URL } from '../../../services/_base.api.url';
import './text_editor_styles.css'

function TextEditor({ content = '', setContent, disabled = false, setParentLoading, token = '', uploadImgUrl = '' }) {
    let [isLoaded, setLoaded] = useState(false)
    const editorRef = useRef()
    const { CKEditor, ClassicEditor } = editorRef.current || {}


    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
            ClassicEditor: require('ckeditor5-custom-build/build/ckeditor'),
        }
        setLoaded(true)
    }, [])

    if (isLoaded) {

        return (

            <CKEditor
                disabled={disabled}
                data={content}
                onReady={editor => {
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "600px",
                            editor.editing.view.document.getRoot()
                        );
                    });

                    setParentLoading(false)
                    // console.log(uploadImgUrl)
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    // console.log(uploadImgUrl)

                    setContent(data)

                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    // console.log(uploadImgUrl)
                    // console.log('Focus.', editor);
                }}
                config={{
                    simpleUpload: {
                        uploadUrl: uploadImgUrl,
                        headers: {
                            'X-Api-Key': `Bearer ${token}`
                        }
                    }
                }}
                editor={ClassicEditor}
            />
        )
    } else {
        <Center>
            <CircularProgress />
        </Center>
    }
}

export default TextEditor