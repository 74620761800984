import { Flex, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { ImFilesEmpty } from 'react-icons/im'
import React from 'react'


function TableEmpty() {
    return (
        <Tr>
            <Td colSpan={7}>
                <Flex flexDirection='column' justifyContent='center' alignItems='center' w='100%' h='200px'>
                    <Icon as={ImFilesEmpty} h='64px' w='64px' color='gray.500' />
                </Flex>
            </Td>
        </Tr>
    )
}

export default TableEmpty