import { Box, Button, Flex, Image, Spacer, Text, Textarea, useToast } from "@chakra-ui/react"
import { Fragment, useContext, useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { AuthContextState } from "../../../../context/AuthContext"
import { errorAxiosToast, updatedToast } from "../../../../lib/const/toast"
import { getPerpustakaan, updatePerpustakaan } from "../../../../services/user.services"
import FileUpload from "../../_components/FileUpload"
import PageTitle from "../../_components/PageTitle"
// import TextEditor from "../../_components/TextEditor"

function PerpustakaanEdit() {
  const [, setTopRightButton] = useOutletContext()

  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [loading, setLoading] = useState(true)
  // const [descriptionPost, setDescriptionPost] = useState('')
  // const [descriptionPostError, setDescriptionPostError] = useState('')
  const [image, setImage] = useState('')
  const [imageUploaded, setImageUploaded] = useState([])
  // const [banner, setBanner] = useState('')
  // const [bannerUploaded, setBannerUploaded] = useState([])

  const toast = useToast()
  // const param = useParams()
  const [auth,] = useContext(AuthContextState)

  const uploadImageHandler = (e) => {
    setImage(e)
    console.log(e)
  }

  const onSubmitHandler = () => {
    setDescriptionError('')
    // setDescriptionPostError('')

    if (!description.trim()) {
      setDescriptionError('Deskripsi harus diisi')
      return
    }

    // if (!descriptionPost.trim()) {
    //   setDescriptionPostError('Deskripsi konten harus diisi')
    //   return
    // }


    setLoading(true)
    updatePerpustakaan(auth, { description, image }).then(data => {
      console.log(data)
      toast(updatedToast)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      toast(errorAxiosToast(err))
      setLoading(false)
    })
  }


  useEffect(() => {
    setTopRightButton(null)
    init()
  }, [])

  const init = () => {
    setLoading(true)
    getPerpustakaan(auth).then(data => {
      console.log(data)
      setDescription(data.data.data.description)
      // setDescriptionPost(data.data.data.description_post || '')
      setImageUploaded(data.data.data.image)
      // setBannerUploaded(data.data.data.banner)
      // setId(data.data.data.id)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }
  return (

    <Fragment>
      <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
        <PageTitle title='Perpustakaan' showIcon={false} mt={0} mb={0} />
      </Box>
      <Box bg='gray.50' color='gray.700' p={4}>
        <Box>
          <Text mb={4} fontWeight={500}>Upload Gambar</Text>
          {
            !image && imageUploaded ?
              <Flex direction='column' alignItems='center' justifyContent='flex-start'>
                <Box  >
                  <Image my={4} maxW={400} maxH={400} src={imageUploaded} objectFit='contain' />
                </Box>
              </Flex> : ''
          }
          <FileUpload accept='image/jpeg,image/png' fileHandler={[image, setImage]} callbackAfterChange={uploadImageHandler} width='100%' />
        </Box>
        <Box my={4} pt={4}>
          <Text fontWeight={500}>Deskripsi</Text>
          <Textarea value={description} disabled={loading} minH={200} maxLength={3000} placeholder='Deskripsi' bg='white' my={4} onChange={(e) => setDescription(e.target.value)} />
          {descriptionError ? <Text fontSize={14} color='red.500'>{descriptionError}</Text> : ''}
        </Box>
        {/* <Box mt={8} width='99.5%' overflow='auto' >
          <TextEditor content={descriptionPost} setContent={setDescriptionPost} disabled={loading} setParentLoading={setLoading} />
          {descriptionPostError ? <Text fontSize={14} color='red.500'>{descriptionPostError}</Text> : ''}
        </Box> */}
      </Box>
      <Flex>
        <Spacer />
        <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={onSubmitHandler}>Simpan</Button>
      </Flex>
    </Fragment>

  )
}

export default PerpustakaanEdit