import axios from "axios"
import { BASE_API_URL } from "./_base.api.url"


export const signIn = (email, password) => {

    const data = JSON.stringify({
        email,
        password
    })

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/login`,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })

}

export const signOut = (user) => {
    // console.log(user)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/logout`
    })
}