import { Box, Button, CloseButton, Flex, Icon, Image, Progress, Spacer, Text, Toast, useToast, VStack } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { FiUploadCloud } from 'react-icons/fi'
import { BiDownvote } from 'react-icons/bi'
import { useEffect } from 'react'
import pdfLogo from '../../../assets/images/pdf-logo.png'
import { imageFormatWrong } from '../../../lib/const/toast'


const imageMimeType = /image\/(png|png|jpeg|gif)/i
const pdfMimeType = 'application/pdf'

function FileUpload({ accept = '*', fileHandler, width = '75%', isLoading = false, callbackAfterChange = (file) => { } }) {
    const hiddenInputRef = useRef(null)
    const [dragActive, setDragActive] = useState(false)
    const [fileState, setFileState] = fileHandler
    const [previewURL, setPreviewURL] = useState('')
    const toast = useToast()

    // Drag & Drop
    const handleDrag = e => {
        e.preventDefault()
        e.stopPropagation()

        if (!isLoading) {
            if (e.type === 'dragenter') {
                setDragActive(true)
            }
            if (e.type === 'dragover') {
                setDragActive(true)
            }
            else if (e.type === 'dragleave') {
                setDragActive(false)
            }
        }
    }

    const handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()

        if (!isLoading) {
            setDragActive(false)
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                const file = e.dataTransfer.files[0]
                setFileState(file)
                callbackAfterChange(file)

            }
        }
    }

    // Click to Select File
    const handleClick = e => {
        hiddenInputRef.current.click()

    }

    const handleChange = e => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            setFileState(file)
            callbackAfterChange(file)

        }
    }

    // Image Previw if Available
    useEffect(() => {
        let fileReader, isCancel = false

        if (fileState) {
            if (fileState.type.match(imageMimeType)) {
                fileReader = new FileReader()
                fileReader.onload = e => {
                    const { result } = e.target
                    if (result && !isCancel) {
                        setPreviewURL(result)
                    }
                }
                fileReader.readAsDataURL(fileState)
            } else if (fileState.type === pdfMimeType) {
                setPreviewURL(pdfLogo)
            } else {
                toast(imageFormatWrong)
            }
        }

        return () => {
            isCancel = true
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort()
            }
        }
    }, [fileState])

    if (!fileState) {

        return (

            <Flex onDragEnter={handleDrag} w={width} alignItems='center' p={4} mt={2} borderRadius={4} borderStyle='dashed' position='relative' borderColor='gray.200' borderWidth={2}>
                <input ref={hiddenInputRef} onChange={handleChange} type='file' disabled={isLoading} accept={accept} style={{ display: 'none' }} />
                {
                    dragActive ?
                        <Flex direction='row' alignItems='center'>
                            <Icon w={16} h={16} as={BiDownvote} color='gray.500' />
                            <Text ml={4} textAlign='center' fontSize={12} color='gray.400'>Drop File Here</Text>
                        </Flex>
                        :
                        <Fragment>
                            <Icon w={16} h={16} as={FiUploadCloud} color='gray.500' />
                            <VStack ml={4} >
                                <Text mr='auto' fontSize={13}>Select a file or drag and drop here</Text>
                                <Text mr='auto' fontSize={12} color='gray.400'>JPG, PNG, or PDF, file size no more than 10MB</Text>
                            </VStack>
                        </Fragment>

                }
                <Spacer />
                {dragActive && <Box onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} position='absolute' w='100%' h='100%' ></Box>}
                <Button onClick={handleClick} colorScheme='twitter' disabled={isLoading} variant='outline'>SELECT FILE</Button>
            </Flex>
        )
    } else {
        return (
            <Box textAlign='center' position='relative' w={width} borderWidth={2} p={4} mt={2} borderColor='gray.200' borderStyle='dashed' borderRadius={4} overflow='auto'>
                <Image src={previewURL} maxW='200px' maxH='200px' display='inline-block' />
                <CloseButton position='absolute' top={0} right={0} color='red' onClick={() => setFileState(null)} />
                {
                    isLoading ? <Progress size='xs' isIndeterminate /> : ''}
            </Box>
        )
    }
}

export default FileUpload