import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'
import React from 'react'
import { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'

function SidebarChild({ link, name, child }) {
    const { pathname } = useLocation()

    const hasChild = () => child.length

    const isActive = () => {
        return pathname.split('/')[2] === link.split('/')[2] &&
            pathname.split('/')[1] === link.split('/')[1]
    }

    const isSubChildActive = (subChildLink) => {
        return pathname.split('/')[3] === subChildLink.split('/')[3]
    }

    return (
        <Fragment>
            {
                hasChild() ?
                    <Accordion allowToggle>
                        <AccordionItem my={2} style={{ border: 'none' }}>
                            <AccordionButton>
                                <Box flex='1' textAlign='left' fontWeight={isActive() ? 700 : 400} color={isActive() ? 'sidebar-active' : 'gray.500'}>
                                    {name}
                                </Box>
                                {hasChild() ? <AccordionIcon color='gray.500' /> : ''}
                            </AccordionButton>
                            <AccordionPanel>
                                {
                                    child.map((item, index) => (
                                        <Link key={index} to={item.link}>
                                            <Box my={2} py={2} pl={4} fontWeight={isSubChildActive(item.link) ? 700 : 400} color={isSubChildActive(item.link) ? 'sidebar-active' : 'gray.500'} _hover={{ cursor: 'pointer', backgroundColor: 'gray.100' }} >
                                                {item.name}
                                            </Box>
                                        </Link >
                                    ))
                                }

                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    :

                    <Link to={link}>
                        <Box my={2} py={2} pl={4} fontWeight={isActive() ? 700 : 400} color={isActive() ? 'sidebar-active' : 'gray.500'} _hover={{ cursor: 'pointer', backgroundColor: 'gray.100' }} >
                            {name}
                        </Box>
                    </Link >
            }
        </Fragment>
    )
}

export default SidebarChild