import { Box, Button, Flex, Grid, GridItem, Image, Input, NumberInput, NumberInputField, Select, Spacer, Text, useToast } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { docChangedToast, thumbnailChangedToast, titleSavedToast, unauthenticateToast } from '../../../../lib/const/toast'
import { addIreUpdateContent, addPublikasiContent, addPublikasiDoc, addPublikasiThumbnail, addPublikasiTitle, getKajian, getPublikasi } from '../../../../services/user.services'
import { BASE_API_URL } from '../../../../services/_base.api.url'
import FileUpload from '../../_components/FileUpload'
import SelectAutoSuggest from '../../_components/SelectAutoSuggest'
import TextEditor from '../../_components/TextEditor'
import pdfImage from '../../../../assets/images/pdf-logo.png'
import imgError from '../../../../assets/images/no-image.png'
import { textCutter } from '../../../../lib/helper/textCutter'
import { getDateNow } from '../../../../lib/helper/getDateNow'
import PageTitle from '../../_components/PageTitle'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import { AuthContextState } from '../../../../context/AuthContext'

function PublikasiAdd({ onEdit = false, customPage = false }) {
    const navigate = useNavigate()
    const [, setTopRightButton] = useOutletContext()
    const toast = useToast()
    const param = useParams()
    const [auth,] = useContext(AuthContextState)
    const [id, setId] = useState('')
    const [fileId, setFileId] = useState('')
    const [title, setTitle] = useState('')
    const [docUrl, setDocUrl] = useState('')
    const [writer, setWriter] = useState('')
    const [kajian, setKajian] = useState('')
    const [terbit, setTerbit] = useState('')
    const [content, setContent] = useState('')
    const [datePublish, setDatePublish] = useState('')
    const [visibility, setVisibility] = useState('public')
    const [titleError, setTitleError] = useState('')
    const [contentError, setContentError] = useState('')
    const [terbitError, setTerbitError] = useState('')
    const [kajianError, setKajianError] = useState('')
    const [writerError, setWriterError] = useState('')
    const [showThumbUploader, setShowThumbUploader] = useState(false)
    const [thumbnail, setThumbnail] = useState('')
    const [thumbnailUrl, setThumbnailUrl] = useState('')
    const [loading, setLoading] = useState(true)
    const [file, setFile] = useState(null)
    const slugPage = customPage ? param.slugPage : ''
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Publikasi'


    const isParamExist = () => {
        return param.slug
    }

    const onPublishHandler = () => {
        setTitleError('')
        setContentError('')
        setTerbitError('')
        setKajianError('')
        setWriterError('')

        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }
        if (writer.trim() === '') {
            setWriterError('Penulis Harus diisi')
            return
        }
        if (content.trim() === '') {
            setContentError('Harus diisi')
            return
        }
        if (terbit.trim() === '') {
            setTerbitError('Tahun Terbit harus diisi')
            return
        }
        if (kajian.trim() === '') {
            setKajianError('Kajian harus diisi')
            return
        }

        setLoading(true)

        let payload = {
            slug: param.slug,
            publish_year: terbit,
            study: kajian,
            title: title,
            content: content,
            published_at: datePublish,
            visibility: visibility,
            author_name: writer
        }

        if (customPage) {
            payload = { ...payload, slugPage }
        }

        addPublikasiContent(auth, payload)
            .then(data => {
                toast({
                    title: 'Sukses',
                    description: 'Publikasi Berhasil Dibuat',
                    status: 'success',
                    position: 'top'
                })
                console.log(data)
                navigate(customPage ? `/halaman/${slugPage}/publication` : '/beranda/publikasi')
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })
    }

    const onTitleHandler = () => {
        setTitleError('')
        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }
        setLoading(true)
        let payload = { title }
        if (customPage) {
            payload = { ...payload, slugPage }
        }

        addPublikasiTitle(auth, payload)
            .then(response => {
                const data = response.data.data
                toast(titleSavedToast)
                setId(data.id)
                console.log(data)
                navigate(customPage ? `/halaman/${slugPage}/publication/${data.slug}/add` : `/beranda/publikasi/${data.slug}/add`)
                setLoading(false)
            })
            .catch(e => {
                // console.log(e)
                const { status, data } = e.response
                if (status === 401) {
                    toast(unauthenticateToast)
                    navigate('/auth/logout')
                }
                if (status === 422) {
                    console.log(data)
                    toast({
                        title: 'Gagal',
                        description: data.message,
                        status: 'error',
                        position: 'top'

                    })
                }
                setLoading(false)

            })
    }

    const uploadThumbnailHandler = (file) => {
        if (file) {
            setLoading(true)
            let payload
            if (customPage) {
                payload = { image: file, slug: param.slug, slugPage }
            } else {
                payload = { image: file, slug: param.slug }

            }
            addPublikasiThumbnail(auth, payload)
                .then(data => {
                    toast(thumbnailChangedToast)
                    console.log(data.data.data)
                    setShowThumbUploader(false)
                    setLoading(false)
                    init(false, true)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    // console.log(e.response)
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                        // setThumbnailUrl('')
                        setThumbnail('')
                    }
                    setLoading(false)
                })
        }
    }

    const uploadDocHandler = (file) => {
        if (file) {
            setLoading(true)
            let payload = { file: file, id: id }

            if (customPage) {
                payload = { ...payload, slugPage }
            }

            addPublikasiDoc(auth, payload)
                .then(data => {
                    toast(docChangedToast)
                    console.log(data.data.data)
                    // setShowThumbUploader(false)
                    setLoading(false)
                    init(true, false)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    // console.log(e.response)
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                        // setThumbnailUrl('')
                        // setThumbnail('')
                    }
                    setLoading(false)
                })
        }
    }

    const init = (onlyDoc = false, onlyThumb = false) => {
        if (!isParamExist()) setLoading(false)
        if (isParamExist()) {
            let payload = { slug: param.slug }
            if (customPage) {
                payload = { ...payload, slugPage }
            }

            getPublikasi(auth, payload)
                .then(res => {
                    const result = res.data.data
                    console.log(result)
                    if (!result.custom_value) {
                        result.custom_value = {}
                    }
                    setId(result.id)
                    setLoading(false)
                    if (!onlyDoc && !onlyThumb) {
                        setTitle(result.title || '')
                        setVisibility(result.visibility || 'public')
                        setDatePublish(result.published_at ? result.published_at.slice(0, 10) : getDateNow())
                        setWriter(result.author_name || '')
                        setContent(result.content || '')
                        setKajian(result.custom_value.study ? result.custom_value.study : '')
                        setTerbit(result.custom_value.publish_year ? result.custom_value.publish_year : '')

                    }


                    setFileId(result.custom_value.file_id ? result.custom_value.file_id : '')

                    setDocUrl(result.url_publication || '')


                    setThumbnailUrl(result.url_thumbnail || '')


                    if (!result.url_thumbnail) setShowThumbUploader(true)


                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    useEffect(() => {
        init()

        setTopRightButton(null)

    }, [])

    return (
        <Grid templateColumns='repeat(12,1fr)' gap={4}>
            <GridItem colSpan={isParamExist() ? 9 : 12} h={20}>
                <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                    <PageTitle title={pageTitle} showIcon={false} mt={0} mb={0} />
                </Box>
                <Box bg='gray.50' color='gray.700' p={4}>
                    <Box>
                        <Text fontWeight={500}>Judul Publikasi</Text>
                        <Input isInvalid={titleError} isDisabled={loading} placeholder='Judul' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                        {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                    </Box>
                    {
                        isParamExist() ?
                            <Fragment>
                                <Box my={8} >
                                    <Text fontWeight={500} mt={8}>Upload Gambar Sampul</Text>
                                    {
                                        showThumbUploader ?
                                            <Fragment>
                                                <FileUpload accept='image/jpg,image/jpeg,image/png' fileHandler={[thumbnail, setThumbnail]} isLoading={loading} width='100%' callbackAfterChange={uploadThumbnailHandler} />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Flex justifyContent='center'>
                                                    <Image maxW={200} src={thumbnailUrl ? thumbnailUrl : imgError} />
                                                </Flex>
                                                <Flex justifyContent='center' mt={4}>
                                                    <Button isLoading={loading} mr={4} colorScheme='teal' onClick={() => setShowThumbUploader(true)} >Ubah</Button>
                                                    {/* <Button isLoading={loading} ml={4} colorScheme='red' >Hapus</Button> */}
                                                </Flex>
                                            </Fragment>
                                    }
                                </Box>
                                <Box>
                                    <Text fontWeight={500}>Upload File</Text>
                                    {
                                        (!loading && docUrl) ?
                                            <Flex rounded={4} p={4} mb={8} color='gray.400' alignItems='center' direction='column' w='100%' _hover={{ bg: 'gray.200', cursor: 'pointer' }} onClick={() => window.open(docUrl, '_blank', 'noreferrer')}>
                                                <Image src={pdfImage} maxH={100} maxW={100} />
                                                <Text fontSize={11} >{textCutter(fileId, 10) + fileId.slice((fileId.length - 1) - 5) + '.pdf'}</Text>
                                            </Flex>
                                            : ''
                                    }
                                    <FileUpload accept='application/pdf' isLoading={loading} fileHandler={[file, setFile]} width='100%' callbackAfterChange={uploadDocHandler} />
                                </Box>
                                <Box mt={16} width='99.5%' overflow='auto' >
                                    {!loading ?
                                        <TextEditor content={content} setContent={setContent} token={auth.token} disabled={loading} setParentLoading={setLoading} uploadImgUrl={`${BASE_API_URL}/pages/${customPage ? slugPage : 'publikasi'}/posts/${id}/image`} />
                                        : ''
                                    }
                                    {contentError ? <Text fontSize={14} color='red.500'>{contentError}</Text> : ''}
                                </Box>
                            </Fragment> : ''
                    }
                </Box>
                <Flex>
                    <Spacer />
                    <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={isParamExist() ? onPublishHandler : onTitleHandler}>Simpan</Button>
                </Flex>
            </GridItem>
            {
                isParamExist() ?
                    <GridItem colSpan={3} h={20}>
                        <Box color='gray.700' bg='gray.50' rounded={4}>
                            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                                <Text>Status & Visibilitas</Text>
                            </Box>
                            <Box p={4}>
                                <Box pb={4}>
                                    <Text>Visibility</Text>
                                    <Select value={visibility} onChange={e => setVisibility(e.target.value)} isDisabled={loading} bg='white'>
                                        <option value='public' >Publik</option>
                                        <option value='private' >Private</option>
                                    </Select>
                                </Box>
                                <Box pb={4}>
                                    <Text>Publish</Text>
                                    {/* <Input type={datePublishType} value={datePublish} onBlur={() => setDatePublishType('text')} onFocus={() => setDatePublishType('date')} onChange={(e) => { setDatePublish(`${format(new Date(e.target.value), 'dd/MM/yyyy')}`); e.target.blur() }} bg='white' placeholder='Tanggal Publish' /> */}
                                    <Input type='date' isDisabled={loading} value={datePublish} onChange={(e) => { setDatePublish(e.target.value); console.log(e.target.value) }} bg='white' min={new Date().toISOString().split("T")[0]} placeholder='Tanggal Publish' />
                                </Box>
                            </Box>
                        </Box>
                        <Box color='gray.700' bg='gray.50' mt={4} rounded={4}>
                            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                                <Text>Detail Publikasi</Text>
                            </Box>
                            <Box p={4}>
                                <Box pb={4}>
                                    <Text>Penulis</Text>
                                    <Input bg='white' isDisabled={loading} placeholder='Penulis' value={writer} onChange={(e) => setWriter(e.target.value)} />
                                    {writerError ? <Text fontSize={14} color='red.500'>{writerError}</Text> : ''}
                                </Box>

                                <Box pb={4}>
                                    <Text>Tahun Terbit</Text>
                                    {/* <NumberInput bg='white' isInvalid={terbitError} isDisabled={loading} placeholder='Tahun Terbit' value={terbit} onChange={(val) => setTerbit(val)}> */}
                                    <Input bg='white' maxLength={4} isInvalid={terbitError} isDisabled={loading} placeholder='Tahun Terbit' value={terbit} onChange={(e) => /^\d+/g.test(e.target.value) || e.target.value === '' ? setTerbit(e.target.value) : ''} />

                                    {/* <NumberInputField /> */}
                                    {/* </NumberInput> */}
                                    {terbitError ? <Text fontSize={14} color='red.500'>{terbitError}</Text> : ''}
                                </Box>

                                <Box pb={4}>
                                    <Text>Kajian</Text>
                                    {
                                        loading ?
                                            <Input bg='white' isDisabled={loading} placeholder='Kajian' value={kajian} />
                                            :
                                            <SelectAutoSuggest isDisabled={loading} placeholder='Kajian' value={kajian} onChange={value => setKajian(value)} token={auth.token} />
                                    }

                                    {kajianError ? <Text fontSize={14} color='red.500'>{kajianError}</Text> : ''}
                                </Box>

                            </Box>
                        </Box>
                    </GridItem> : ''
            }
        </Grid>
    )
}


export default PublikasiAdd