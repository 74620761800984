import { Box, Image, Menu } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/ire-logo.png';

function Navbar() {
    return (
        <Menu>
            <Box bg="gray.100" py={5} pl={14} >
                <Box width='52px' height='40px'>

                    <Link to="/">
                        <Image src={logo} alt='IRE logo' />
                    </Link>
                </Box>
            </Box>
        </Menu>

    )
}

export default Navbar