import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import img from '../../../assets/images/export_success.png'

function ExportSuccessAlert({ cancelRef, onClose, isOpen }) {
    return (
        <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogBody>
                    <Flex mt={8} mx={16} mb={4} justifyContent='center'>
                        <Image src={img} />
                    </Flex>
                    <Text textAlign='center' mb={4} fontWeight={600} >Data Berhasil Di Download</Text>
                    <Flex mb={8} justifyContent='center'>
                        <Button width='50%' ref={cancelRef} onClick={onClose} colorScheme='teal' >Ok</Button>
                    </Flex>
                </AlertDialogBody>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default ExportSuccessAlert