import { Box, Flex, HStack, Input, InputGroup, InputRightElement, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import StatsBox from '../_components/StatsBox'
import TableData from '../_components/TableData'
import { dashboardColumns, getDashboardColumns, ireUpdateColumns, publikasiColumns } from '../../../lib/const/table-columns'
import { useOutletContext } from 'react-router-dom'
import { getAllIreUpdate, getAllPublikasi, getCount, getFormDownload, getFormDownloads, getIreUpdate, getLoginStatus, getPublication, getPublications, getPublikasi } from '../../../services/user.services'
import { useContext } from 'react'
import PageTitle from '../_components/PageTitle'
import { AuthContextState } from '../../../context/AuthContext'

function Dashboard() {
    const [, setTopRightButton] = useOutletContext()
    const [auth,] = useContext(AuthContextState)
    const [countIreUpdate, setCountIreUpdate] = React.useState(0)
    const [countPublikasi, setCountPublikasi] = React.useState(0)
    const [countPublikasiDownload, setCountPublikasiDownload] = React.useState(0)
    const [countHalamanCustom, setCountHalamanCustom] = React.useState(0)
    const [ireUpdate, setIreUpdate] = React.useState([])
    const [publikasi, setPublikasi] = React.useState([])
    const [publikasiDownload, setPublikasiDownload] = React.useState([])
    const [isLoadingCount, setLoadingCount] = React.useState(true)
    const [isLoadingIreUpdate, setLoadingIreUpdate] = React.useState(true)
    const [isLoadingPublikasi, setLoadingPublikasi] = React.useState(true)
    const [isLoadingDownload, setLoadingDownload] = React.useState(true)

    const setAllLoading = (value) => {
        setLoadingCount(value)
        setLoadingIreUpdate(value)
        setLoadingPublikasi(value)
        setLoadingDownload(value)
    }

    const init = () => {

        // GET COUNT
        getCount(auth)
            .then(response => {
                setCountIreUpdate(response.data.data.ire_update)
                setCountPublikasi(response.data.data.publication)
                setCountPublikasiDownload(response.data.data.publication_download)
                setCountHalamanCustom(response.data.data.custom_page)
                // console.log('1, COUNT')
                setLoadingCount(false)
            })
            .catch(e => {
                console.log(e)
                setLoadingCount(false)
            })

        // GET IRE UPDATE
        getAllIreUpdate(auth, {})
            .then(response => {
                const data = response.data.data.map(item => {
                    item.author_name = item.author_name ? item.author_name : 'Admin'
                    return item
                }).slice(0, 3)

                setIreUpdate(data)
                // console.log('IreUpdate', response.data.data)
                // console.log('2, IREUPDATE')
                // setLoading(false)
                setLoadingIreUpdate(false)
            })
            .catch(e => {
                setLoadingIreUpdate(false)
                console.log(e)
            })

        // GET PUBLIKASI
        getAllPublikasi(auth, {})
            .then(response => {
                const data = response.data.data.map(item => {
                    item.author_name = item.author_name ? item.author_name : 'Admin'
                    return item
                }).slice(0, 3)
                setPublikasi(data)
                // console.log('Publikasi', response.data.data)
                // setLoading(false)
                setLoadingPublikasi(false)
                // console.log('3, PUBLIKASI')

            })
            .catch(e => {
                setLoadingPublikasi(false)
                console.log(e)
            })

        // GET RIWAYAT DOWNLOAD
        getFormDownloads(auth)
            .then(response => {
                setPublikasiDownload(response.data.data.slice(0, 3))
                // console.log('FormDownload', response.data.data)
                // setLoading(false)

                setLoadingDownload(false)
                // console.log('4, RIWAYAT DOWNLOAD')
            })
            .catch(e => {
                setLoadingDownload(false)
                console.log(e)
            })
    }

    useEffect(() => {
        setTopRightButton(null)
        init();
    }, [])

    const tableIreUpdateProps = {
        columns: getDashboardColumns('ire-update'),
        data: ireUpdate,
        isLoading: isLoadingIreUpdate,
        showMore: {
            name: 'Menuju Ire Update', url: '/beranda/ire-update'
        }
    }

    const tablePublikasiProps = {
        columns: getDashboardColumns('publikasi'),
        data: publikasi,
        isLoading: isLoadingPublikasi,
        showMore: {
            name: 'Menuju Publikasi', url: '/beranda/publikasi'
        }
    }

    const tablePublikasiDownloadProps = {
        columns: getDashboardColumns('download'),
        data: publikasiDownload,
        isLoading: isLoadingDownload,
        showMore: {
            name: 'Menuju Riwayat Unduh', url: '/download'
        }
    }

    return (
        <div>
            <Flex>
                <StatsBox isLoading={isLoadingCount} title={'IRE Update'} value={countIreUpdate} bg={'green.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Publikasi'} value={countPublikasi} bg={'red.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Publikasi Diunduh'} value={countPublikasiDownload} bg={'blue.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Halaman Custom'} value={countHalamanCustom} bg={'teal.500'} />
            </Flex>

            <RenderTable tableProps={tablePublikasiDownloadProps} title='Riwayat Publikasi Diunduh' />
            <RenderTable tableProps={tablePublikasiProps} title='Publikasi' />
            <RenderTable tableProps={tableIreUpdateProps} title='Ire Update' />

        </div>
    )

}

export default Dashboard

function RenderTable({ tableProps, title }) {
    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PageTitle title={title} />
            {/* <Text as='b' fontSize='md'>{title}</Text> */}
            <TableData {...tableProps} />
        </Box>

    )
}