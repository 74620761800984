import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function CustomBreadcrumb({ listItem }) {
    return (
        <Breadcrumb separator={<FaChevronRight size={10} />}>
            {
                listItem.map(({ name, link }, index) => (
                    <BreadcrumbItem key={index} fontWeight={index === listItem.length - 1 ? 700 : 500} >
                        <BreadcrumbLink as={Link} href={link} isCurrentPage>
                            {name}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ))
            }
        </Breadcrumb>
    )
}

export default CustomBreadcrumb