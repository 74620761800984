import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Avatar, Box, Button, Flex, Input, InputGroup, InputRightElement, Select, Spacer, Text, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { AuthContextState } from '../../../../../context/AuthContext'
import { useUrlQuery } from '../../../../../hooks/useUrlQuery'
import { organisasiColumns } from '../../../../../lib/const/table-columns'
import { deletedToast, orderTeamToastFailed, orderTeamToastSuccess, pinContentToast, unauthenticateToast, unpinContentToast } from '../../../../../lib/const/toast'
import { changeTeamOrder, deleteTeam, getTeams, pinTeam, unpinTeam } from '../../../../../services/user.services'
import PageTitle from '../../../_components/PageTitle'
import TableData from '../../../_components/TableData'

function Organisasi() {
    const [, setTopRightButton] = useOutletContext()
    const [dndMode, setDndMode] = useState(false)
    const [teamOrder, setTeamOrder] = useState([])
    const navigate = useNavigate()
    const [auth] = useContext(AuthContextState)
    const toast = useToast()
    const [teams, setTeams] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const { urlQuery, addQuery } = useUrlQuery()

    const dropHandler = (droppedItem) => {
        if (!droppedItem.destination) return
        let updatedList = [...teams]
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
        setTeams(updatedList)
        setTeamOrder(updatedList.map((item, index) => ({ id: item.id, order: index })))
        console.log(updatedList, 'updated')
    }

    const submitOder = () => {
        setLoading(true)
        setDndMode(false)
        changeTeamOrder(auth, teamOrder)
            .then(res => {
                init()
                toast(orderTeamToastSuccess)
            })
            .catch(e => {
                toast(orderTeamToastFailed)
                setLoading(false)
                console.log(e)
            })
    }

    const deleteHandler = (id) => {
        setLoading(true)
        deleteTeam(auth, id)
            .then(data => {
                toast(deletedToast)
                console.log(data)
                init()
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })
    }

    // const pinHandler = (data) => {
    //     setLoading(true)

    //     let handler = data.visibility === 'public' ? pinTeam : unpinTeam
    //     let id = data.id;
    //     handler(auth, id)
    //         .then(response => {
    //             toast(response.data.data.visibility === 'public' ? pinContentToast : unpinContentToast)
    //             console.log(data)
    //             init()
    //         })
    //         .catch(e => {
    //             setLoading(false)
    //             console.log(e)
    //         })
    // }

    const responseHandler = (response) => {
        const team = response.data.data.map(item => {
            item.action = {
                delete: () => deleteHandler(item.id),
                slug: item.slug
            }
            return item
        })
        setTeams(team)
        setTeamOrder(team.map((item) => ({ id: item.id, order: item.order })))
        console.log(team)

        setMetaTable({
            // current_page: response.data.meta.current_page,
            per_page: response.data.length,
            from: 1,
            to: response.data.length,
            total: response.data.length,
            // next: response.data.links.next,
            // prev: response.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        const { status } = e.response

        if (status === 401) {
            toast(unauthenticateToast)
            navigate('/auth/logout')
        }

        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }

    const init = () => {
        getTeams(auth, urlQuery.get('p') || 1)
            .then(responseHandler)
            .catch(errorHandler)
    }


    const tableProps = {
        columns: organisasiColumns,
        data: teams,
        isLoading,
        metaTable,
        prevPageHandler,
        nextPageHandler
    }
    console.log('team order', teamOrder)
    useEffect(() => {
        init()
        setTopRightButton({
            text: 'Tambah Anggota',
            icon: <AddIcon />,
            onClick: () => navigate('/beranda/ire/organisasi/add')
        })
    }, [urlQuery])

    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PageTitle mb={4} title='Organisasi' />
            {
                !dndMode ?
                    <Fragment>
                        <Flex>
                            <Select placeholder='Urutkan' bg='white' width='20%' mr={2} />
                            <Spacer />
                            <InputGroup width='30%'>
                                <Input placeholder='Ketik untuk mencari' bg='white' />
                                <InputRightElement color='gray.700' >
                                    <Search2Icon />
                                </InputRightElement>
                            </InputGroup>
                            <Button disabled={isLoading} colorScheme='teal' ml={4} onClick={() => setDndMode(true)} >Ubah Urutan</Button>
                        </Flex>
                        <TableData {...tableProps} />
                    </Fragment>
                    :
                    <DragDropContext onDragEnd={dropHandler}>
                        <Droppable droppableId='drag-container'>
                            {
                                (provided) => (
                                    <Box className='container' ref={provided.innerRef} {...provided.droppableProps}>
                                        <Flex mb={2} >
                                            <Spacer />
                                            <Button mr={2} colorScheme='red' onClick={() => setDndMode(false)} >Kembali</Button>
                                            <Button colorScheme='green' onClick={submitOder} >Simpan</Button>
                                        </Flex>

                                        {
                                            teams.map((item, index) => (
                                                <Draggable key={item.id} draggableId={`${item.id}`} index={index} >
                                                    {(provided) => (
                                                        <Box mt={2} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} >
                                                            <Flex justifyContent='space-between' alignItems='center' borderWidth={1} py={2} px={36} bg='white'>
                                                                <Flex alignItems='center'>
                                                                    <Avatar mr={5} name={item.name} src={item.avatar} />
                                                                    <Box>
                                                                        <Text fontWeight={500} textAlign='left' >{item.name}</Text>
                                                                    </Box>
                                                                </Flex>
                                                                <Box>{item.position}</Box>
                                                            </Flex>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </Box>

                                )
                            }

                        </Droppable>
                    </DragDropContext>
            }
        </Box>
    )
}

export default Organisasi