import { ArrowDownIcon, ArrowForwardIcon, ArrowRightIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, HStack, scaleFadeConfig, Select, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRowSelect, useSortBy, useTable } from 'react-table'
import { TableCheckbox } from './TableCheckbox'
import TableEmpty from './TableEmpty'
import TableLoading from './TableLoading'

function TableData({ columns = [], data = [], isLoading = false, metaTable = {}, prevPageHandler = () => { }, nextPageHandler = () => { }, showMore = null }) {

    const { from, to, total, next, prev } = metaTable || { from: null, to: null, total: null, next: null, prev: null }
    // console.log('data', data)
    const navigate = useNavigate()
    // columns = useMemo(() => columns, [])
    // data = useMemo(() => data, [])
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable({
        columns,
        data
    },
        useSortBy,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => {

                            return (
                                <TableCheckbox {...getToggleAllRowsSelectedProps()} />
                            )
                        },
                        Cell: ({ row }) => {

                            return (
                                <TableCheckbox {...row.getToggleRowSelectedProps()} />
                            )
                        },
                    },
                    ...columns
                ]
            })
        }
    )

    // console.log(selectedFlatRows.map(row => row.original))
    return (
        <TableContainer border='1px' borderRadius={4} borderColor='gray.200' mt={4} bg='white'  >
            <Table variant='simple' {...getTableProps()} >
                <Thead fontWeight={700} fontSize={12} bg='gray.50'>
                    {
                        headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()} >
                                {
                                    headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            <Flex>
                                                {column.render('Header')}
                                                {column.isSorted ? (column.isSortedDesc ? <ArrowUpIcon /> : <ArrowDownIcon />) : ''}
                                            </Flex>
                                        </Th>

                                    ))
                                }
                            </Tr>
                        ))
                    }
                </Thead>
                <Tbody bg='white' fontSize={14} {...getTableBodyProps()}>
                    {
                        isLoading ?
                            <TableLoading />
                            :
                            (
                                data.length !== 0 ?
                                    rows.map(row => {
                                        prepareRow(row)

                                        return (
                                            <Tr {...row.getRowProps()}>
                                                {
                                                    row.cells.map(cell => {
                                                        return <Td {...cell.getCellProps()} >{cell.render('Cell')}</Td>
                                                    })
                                                }
                                            </Tr>
                                        )
                                    })
                                    : <TableEmpty />
                            )
                    }
                </Tbody>
                {
                    !showMore ?
                        <TableCaption fontSize={14} bg='white'>

                            <Flex >
                                <Box width={20}>
                                    <Text display='inline'>Tampilkan baris per halaman</Text>
                                    <Select defaultValue='10' display='inline' size='sm' mx={2} bg='white' disabled>
                                        <option value='10'>10</option>
                                        <option value='12'>12</option>
                                        <option value='15'>15</option>
                                    </Select>
                                </Box>
                                <Spacer />

                                <Box>
                                    <Flex direction='row' alignItems='center'>
                                        <Text display='inline' color='gray.700'> <Text display='inline' fontWeight={600}>{from}-{to}</Text> of {total}</Text>
                                        <HStack mt={1} mx={8} spacing={8} >
                                            <Button disabled={!prev || isLoading} onClick={prevPageHandler} size='sm' colorScheme='teal' variant='ghost' rightIcon={<ChevronLeftIcon color='gray.700' size={14} />} ></Button>
                                            <Button disabled={!next || isLoading} onClick={nextPageHandler} size='sm' colorScheme='teal' variant='ghost' rightIcon={<ChevronRightIcon color='gray.700' size={14} />} ></Button>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>

                        </TableCaption> :
                        <TableCaption >
                            <Text mb={4} _hover={{ cursor: 'pointer', color: '#537645' }} onClick={() => navigate(showMore.url)} >{showMore.name} <ArrowForwardIcon size={4} /> </Text>
                        </TableCaption>
                }
            </Table>
            {/* <code>
                {
                    JSON.stringify({
                        selectedFlatRows: selectedFlatRows.map(row => row.original)
                    })
                }
            </code> */}
        </TableContainer>
    )
}

export default TableData