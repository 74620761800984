import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { HiOutlineDownload } from 'react-icons/hi'
import { Box, Button, Center, Flex, Img, Input, InputGroup, InputRightElement, Select, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import CustomModal from '../_components/CustomModal'
import TableData from '../_components/TableData'
import { useState } from 'react'
import { Fragment } from 'react'
import { unauthenticateToast } from '../../../lib/const/toast'
import { getExportedData, getFormDownloads, getStatsChart } from '../../../services/user.services'
import { formDownloadColumns } from '../../../lib/const/table-columns'
import PageTitle from '../_components/PageTitle'
import { getSlugFromURL } from '../../../lib/helper/getSlugFromUrl'
import { capFirstLetter } from '../../../lib/helper/capitalizeFirstLetter'
import DownloadChart from '../_components/DownloadChart'
import { getDateNow } from '../../../lib/helper/getDateNow'
import { getDownloadChartDataset } from '../../../lib/helper/getDownloadChartDataset'
import ExportSuccessAlert from '../_components/ExportSuccessAlert'
import { AuthContextState } from '../../../context/AuthContext'
import { useUrlQuery } from '../../../hooks/useUrlQuery'

function Download() {
    const [auth] = useContext(AuthContextState)
    const toast = useToast()
    const [download, setDownload] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [chartData, setChartData] = useState('')
    const { urlQuery, addQuery } = useUrlQuery()
    const navigate = useNavigate()

    const exportSuccessDisclosure = useDisclosure()
    const exportSuccessCancelRef = useRef()

    const [, setTopRightButton] = useOutletContext()
    const disclosureInit = useDisclosure()
    const disclosureExportInit = useDisclosure()
    const addDownloadRef = useRef(null)
    const exportRef = useRef(null)

    // Show Download
    const [description, setDescription] = useState('')

    const disclosure = {
        ...disclosureInit,
        onClose: () => {
            disclosureInit.onClose()
            setDescription('')
        }
    }

    const disclosureExport = {
        ...disclosureExportInit,
        onClose: () => {
            disclosureExportInit.onClose()

        }
    }

    // Begin Table Handler Function ========================================

    const responseHandler = (response) => {
        const downloadResponse = response.data.data.map(item => {
            item.action = {
                onOpenReason: () => {
                    disclosure.onOpen()
                    setDescription(item.reason)
                },
            }
            item.product = item.url_post ? capFirstLetter(getSlugFromURL(item.url_post)) : 'Tidak Terdata'
            return item
        })

        setDownload(downloadResponse)

        setMetaTable({
            current_page: response.data.meta.current_page,
            per_page: response.data.meta.per_page,
            from: response.data.meta.from,
            to: response.data.meta.to,
            total: response.data.meta.total,
            next: response.data.links.next,
            prev: response.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)

        const { status } = e.response

        if (status === 401) {
            toast(unauthenticateToast)
            navigate('/auth/logout')
        }



        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }


    const init = () => {
        getStatsChart(auth)
            .then(res => {
                const data = res.data.data
                setChartData(getDownloadChartDataset(data))


            })
            .catch(e => {
                console.log(e)
            })


        setEndDate(getDateNow())
        let date = new Date(getDateNow())
        date = new Date(date.getTime() - 1000 * 60 * 60 * 24 * 30)
        setStartDate(date.toISOString().split('T')[0])

        getFormDownloads(auth, urlQuery.get('p') || 1)
            .then(responseHandler)
            .catch(errorHandler)
    }

    const tableProps = {
        columns: formDownloadColumns,
        data: download,
        isLoading,
        metaTable,
        nextPageHandler,
        prevPageHandler
    }
    // End Table Handler Function ========================================


    // Begin Export Handler Function ========================================
    const exportHandler = () => {
        setLoading('true')
        getExportedData(auth, { startDate, endDate })
            .then(res => {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'publication_download_forms.xlsx');
                document.body.appendChild(link);
                link.click();
                disclosureExport.onClose()
                exportSuccessDisclosure.onOpen()
                setLoading(false)
            })
            .catch(e => {
                disclosureExport.onClose()
                console.log(e)
                setLoading(false)
            })
    }
    // End Of Export Handler Function ========================================


    const customModalBodyProps = {
        description
    }

    useEffect(() => {
        init()
        setTopRightButton({
            text: 'Export Data',
            icon: <HiOutlineDownload />,
            onClick: disclosureExport.onOpen
        })
    }, [urlQuery])

    console.log(chartData)

    return (
        <Fragment>
            <CustomModal loading={isLoading} size='xs' disclosure={disclosureExport} ref={exportRef} closeOnOverlayClick={false} closeButton={false} title={<Box bg='gray.100' p={3}  >Download Data</Box>} body={ExportBody({ startDate, setStartDate, endDate, setEndDate, disclosureExport, isLoading, exportHandler })} footer='' />
            <ExportSuccessAlert {...exportSuccessDisclosure} cancelRef={exportSuccessCancelRef} />
            <DownloadChart {...chartData} />
            <Box bg='gray.50' p={4} mt={8} color='gray.700'>
                <CustomModal loading={isLoading} disclosure={disclosure} ref={addDownloadRef} closeOnOverlayClick={false} title="Alasan Mendownload" body={AddBody(customModalBodyProps)} footer={AddFooter({ disclosure })} />
                <PageTitle mb={4} title='Download' />

                <Flex>
                    <Select placeholder='Tanggal' bg='white' width='20%' mr={2} />
                    <Select placeholder='Kategori' bg='white' width='20%' />
                    <Spacer />
                    <InputGroup width='30%'>
                        <Input placeholder='Ketik untuk mencari' bg='white' />
                        <InputRightElement color='gray.700' >
                            <Search2Icon />
                        </InputRightElement>
                    </InputGroup>
                </Flex>
                <TableData {...tableProps} />
            </Box>
        </Fragment>
    )
}

function ExportBody({ startDate, setStartDate, endDate, setEndDate, disclosureExport, isLoading, exportHandler }) {
    return (
        <Fragment>
            <Box>
                <Text>Tanggal Awal</Text>
                <Input isDisabled={isLoading} type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value); console.log(e.target.value) }} bg='white' max={endDate} placeholder='Tanggal Awal' />
            </Box>
            <Box mt={4}>
                <Text>Tanggal Akhir</Text>
                <Input isDisabled={isLoading} type='date' value={endDate} onChange={(e) => { setEndDate(e.target.value); console.log(e.target.value) }} bg='white' min={startDate} placeholder='Tanggal Akhir' />
            </Box>
            <Flex mt={8} direction='column'  >
                <Button isDisabled={isLoading} bg='teal.500' colorScheme='teal' onClick={exportHandler}>
                    Download
                </Button>
                <Button isDisabled={isLoading} onClick={disclosureExport.onClose} variant='outline' colorScheme='red' mt={4}>
                    Kembali
                </Button>
            </Flex>
        </Fragment>
    )
}





function AddBody({ description }) {

    return (
        <Fragment>
            <Box>
                <Text>{description}</Text>
            </Box>
        </Fragment>
    )
}

function AddFooter({ disclosure }) {
    return (
        <Fragment>
            <Button onClick={disclosure.onClose}>
                Kembali
            </Button>
        </Fragment>
    )
}

export default Download
