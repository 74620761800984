
import { Box, Button, Flex, Image, Spacer, Text, Textarea, useToast } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from '../../../../../context/AuthContext'
import { errorAxiosToast, updatedToast } from '../../../../../lib/const/toast'
import { getAbout, updateAbout } from '../../../../../services/user.services'
import { BASE_API_URL } from '../../../../../services/_base.api.url'
import FileUpload from '../../../_components/FileUpload'
import PageTitle from '../../../_components/PageTitle'
import TextEditor from '../../../_components/TextEditor'

function TentangEdit() {
    const [, setTopRightButton] = useOutletContext()

    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [loading, setLoading] = useState(true)
    const [descriptionPost, setDescriptionPost] = useState('')
    const [descriptionPostError, setDescriptionPostError] = useState('')
    const [image, setImage] = useState('')
    const [imageError, setImageError] = useState('')
    const [imageUploaded, setImageUploaded] = useState([])
    const [id, setId] = useState('')
    const toast = useToast()
    const param = useParams()
    const [auth,] = useContext(AuthContextState)

    const init = () => {
        setLoading(true)
        getAbout(auth).then(data => {
            console.log(data)
            setDescription(data.data.data.description)
            setDescriptionPost(data.data.data.description_post)
            // setImage(data.data.data.image)
            setImageUploaded(data.data.data.image)
            setId(data.data.data.id)
            setLoading(false)
        }
        ).catch(e => {
            console.log(e)
            setLoading(false)
        })
    }

    const uploadImageHandler = (e) => {
        setImage(e)
        console.log(e)
    }


    const onSubmitSectionHandler = () => {
        setLoading(true)

        if (description === '') {
            setDescriptionError('Deskripsi tidak boleh kosong')
            setLoading(false)
            return
        }

        if (descriptionPost === '') {
            setDescriptionPostError('Deskripsi tidak boleh kosong')
            setLoading(false)
            return
        }

        // if (image === '') {
        //     setImageError('Gambar tidak boleh kosong')
        //     setLoading(false)
        //     return
        // }

        const data = {
            description: description,
            description_post: descriptionPost,
            image: image ?? null,
            // id: id
        }

        updateAbout(auth, data).then(data => {
            console.log(data)
            toast(updatedToast)
            init()
            setLoading(false)
        }).catch(e => {
            console.log(e)
            toast(errorAxiosToast(e))
            setLoading(false)
        })
    }


    useEffect(() => {
        init()
        setTopRightButton(null)
    }, [])

    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title='Tentang Ire' showIcon={false} mt={0} mb={0} />
            </Box>
            <Box bg='gray.50' color='gray.700' p={4}>
                <Box>
                    <Text fontWeight={500}>Upload Gambar Thumbnail</Text>
                    {
                        !image && imageUploaded ?
                            <Flex direction='column' alignItems='center' justifyContent='flex-start'>
                                <Box  >
                                    <Image my={4} src={imageUploaded} maxH={400} maxW={400} objectFit='contain' />
                                </Box>
                            </Flex> : ''
                    }
                    <FileUpload accept='image/jpeg,image/png' fileHandler={[image, setImage]} callbackAfterChange={uploadImageHandler} width='100%' />
                    {imageError ? <Text fontSize={14} color='red.500'>{imageError}</Text> : ''}
                </Box>
                <Box mt={8} width='99.5%' overflow='auto' >
                    <Text fontWeight={500}>Deskripsi</Text>
                    <Textarea value={description} disabled={loading} maxLength={3000} placeholder='Deskripsi' bg='white' my={4} onChange={(e) => setDescription(e.target.value)} />
                    {descriptionError ? <Text fontSize={14} color='red.500'>{descriptionError}</Text> : ''}
                </Box>
                <Box mt={8} width='99.5%' overflow='auto' >
                    <Text mb={4} fontWeight={500}>Konten</Text>
                    <TextEditor content={descriptionPost} setContent={setDescriptionPost} disabled={loading} setParentLoading={setLoading} token={auth.token} uploadImgUrl={`${BASE_API_URL}/tentang-ire/image`} />
                    {descriptionPostError ? <Text fontSize={14} color='red.500'>{descriptionPostError}</Text> : ''}
                </Box>
            </Box>

            <Flex>
                <Spacer />
                <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={onSubmitSectionHandler}>Simpan</Button>
            </Flex>
        </Fragment>

    )
}

export default TentangEdit