import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import React from 'react'

function DrawerDashboard({ isOpen, ref, onClose, children }) {
    return (
        <Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={ref} >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                    {children}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default DrawerDashboard