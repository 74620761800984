import { Box, Checkbox, Icon } from '@chakra-ui/react'
import React from 'react'
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs'

function PinnedCheckBox({ isChecked, onClick = () => { } }) {
    // return (
    //     <Checkbox onClick={() => console.log('click')} isChecked={isChecked ? true : false} />
    // )

    return (
        <Box onClick={onClick} _hover={{ cursor: 'pointer' }} >

            {
                isChecked ?
                    <Icon as={BsCheckSquareFill} boxSize={4} color='blue.400' />
                    :
                    <Icon as={BsSquare} boxSize={4} />
            }
        </Box>
    )
}

export default PinnedCheckBox