import { Badge } from '@chakra-ui/react'
import React from 'react'

function StatusBadge({ status, message }) {
    return (
        status === 'success' ?
            <Badge color='white' bg='green.500'>{message}</Badge> :
            <Badge color='white' bg='yellow.500'>{message}</Badge>
    )
}

export default StatusBadge