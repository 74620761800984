import { createBrowserRouter } from 'react-router-dom';
import AuthRoot from '../pages/Auth/AuthRoot';
import Komentar from '../pages/Dashboard/Komentar/Komentar';
import Dashboard from '../pages/Dashboard/Dashboard/Dashboard';
import DashboardRoot from '../pages/Dashboard/DashboardRoot';
import Banner from '../pages/Dashboard/Beranda/Banner/Banner';
import Download from '../pages/Dashboard/Download/Download';
import PageNotFound from '../pages/PageNotFound';
import IreUpdate from '../pages/Dashboard/Beranda/IreUpdate/IreUpdate';
import IreUpdateAdd from '../pages/Dashboard/Beranda/IreUpdate/IreUpdateAdd';
import TentangEdit from '../pages/Dashboard/Beranda/TentangIre/Tentang/TentangEdit';
import Organisasi from '../pages/Dashboard/Beranda/TentangIre/Organisasi/Organisasi';
import OrganisasiAdd from '../pages/Dashboard/Beranda/TentangIre/Organisasi/OrganisasiAdd';
import NextGenEdit from '../pages/Dashboard/Beranda/NextGen/NextGenEdit';
import Publikasi from '../pages/Dashboard/Beranda/Publikasi/Publikasi';
import Foto from '../pages/Dashboard/Beranda/GaleriFoto/Foto';
import FotoAdd from '../pages/Dashboard/Beranda/GaleriFoto/FotoAdd';
import Video from '../pages/Dashboard/Beranda/GaleriVideo/Video';
import PublikasiAdd from '../pages/Dashboard/Beranda/Publikasi/PublikasiAdd';
import VideoAdd from '../pages/Dashboard/Beranda/GaleriVideo/VideoAdd';
import TrainingEdit from '../pages/Dashboard/Beranda/Training/TrainingEdit';
import PerpustakaanEdit from '../pages/Dashboard/Beranda/Perpustakaan/PerpustakaanEdit';
import Halaman from '../pages/Dashboard/Halaman/Halaman';
import HalamanAdd from '../pages/Dashboard/Halaman/HalamanAdd';
import CustomPageWrapper from '../pages/CustomPageWrapper';


export const routes = createBrowserRouter([
    {
        path: '/',
        element: <DashboardRoot />,
        errorElement: <PageNotFound />,
        children: [
            // DASHBOARD
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            // BERANDA
            {
                path: 'beranda/banner',
                element: <Banner />
            },
            {
                path: 'beranda/ire-update',
                element: <IreUpdate />
            },
            {
                path: 'beranda/ire-update/add',
                element: <IreUpdateAdd />
            },
            {
                path: 'beranda/ire-update/:slug/add',
                element: <IreUpdateAdd />
            },
            {
                path: 'beranda/ire-update/:slug/edit',
                element: <IreUpdateAdd onEdit={true} />
            },

            {
                path: 'beranda/ire/tentang',
                element: <TentangEdit />
            },
            {
                path: 'beranda/ire/tentang/edit',
                element: <TentangEdit />
            },
            {
                path: 'beranda/ire/organisasi',
                element: <Organisasi />
            },
            {
                path: 'beranda/ire/organisasi/add',
                element: <OrganisasiAdd />
            },
            {
                path: 'beranda/ire/organisasi/:id/edit',
                element: <OrganisasiAdd isEdit={true} />
            },
            {
                path: 'beranda/next-gen/edit',
                element: <NextGenEdit />
            },
            {
                path: 'beranda/publikasi',
                element: <Publikasi />
            },
            {
                path: 'beranda/publikasi/add',
                element: <PublikasiAdd />
            },
            {
                path: 'beranda/publikasi/:slug/add',
                element: <PublikasiAdd />
            },
            {
                path: 'beranda/publikasi/:slug/edit',
                element: <PublikasiAdd onEdit={true} />
            },

            {
                path: 'beranda/foto',
                element: <Foto />
            },
            {
                path: 'beranda/foto/add',
                element: <FotoAdd />
            },
            {
                path: 'beranda/foto/:id/edit',
                element: <FotoAdd onEdit={true} />
            },
            {
                path: 'beranda/video',
                element: <Video />
            },
            {
                path: 'beranda/video/add',
                element: <VideoAdd />
            },
            {
                path: 'beranda/video/:id/edit',
                element: <VideoAdd onEdit={true} />
            },
            {
                path: 'beranda/training/edit',
                element: <TrainingEdit />
            },
            {
                path: 'beranda/perpustakaan/edit',
                element: <PerpustakaanEdit />
            },

            // HALAMAN
            {
                path: 'halaman',
                element: <Halaman />
            },
            {
                path: 'halaman/add',
                element: <HalamanAdd />
            },
            {
                path: 'halaman/:id/edit',
                element: <HalamanAdd onEdit={true} />
            },

            {
                path: 'halaman/:slugPage/post',
                element: <CustomPageWrapper><IreUpdate customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/post/add',
                element: <CustomPageWrapper><IreUpdateAdd customPage={true} /></CustomPageWrapper>

            },
            {
                path: 'halaman/:slugPage/post/:slug/add',
                element: <CustomPageWrapper><IreUpdateAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/post/:slug/edit',
                element: <CustomPageWrapper><IreUpdateAdd customPage={true} onEdit={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication',
                element: <CustomPageWrapper><Publikasi customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/add',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/:slug/add',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/:slug/edit',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} onEdit={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery',
                element: <CustomPageWrapper><Foto customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery/add',
                element: <CustomPageWrapper><FotoAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery/:id/edit',
                element: <CustomPageWrapper><FotoAdd onEdit={true} customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video',
                element: <CustomPageWrapper><Video customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video/add',
                element: <CustomPageWrapper><VideoAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video/:id/edit',
                element: <CustomPageWrapper><VideoAdd onEdit={true} customPage={true} /></CustomPageWrapper>
            },

            {
                path: 'download',
                element: <Download />
            },
            // COMMENTS
            {
                path: 'komentar',
                element: <Komentar />,
            },
            {
                path: 'download',
                element: <Download />,
            },
        ]
    },
    {
        path: '/auth',
        element: <AuthRoot />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: 'logout',
                element: <></>
            }
        ]
    },


])