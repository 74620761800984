import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
        'text': '#404040',
        'btn-login': '#23321D',
        'circular-progress': '#23321D',
        'sidebar-active': '#537645',
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
})

export default theme