import { capFirstLetter } from "./capitalizeFirstLetter"

const color = [
    {
        borderColor: "rgb(244, 67, 54)",
        backgroundColor: "rgba(244, 67, 54, 0.5)"
    },
    {
        borderColor: "rgb(63, 81, 181)",
        backgroundColor: "rgba(63, 81, 181, 0.5)"
    },
    {
        borderColor: "rgb(3, 168, 244)",
        backgroundColor: "rgba(3, 168, 244, 0.5)"
    },
    {
        borderColor: "rgb(255, 193, 7)",
        backgroundColor: "rgba(255, 193, 7, 0.5)"
    },
    {
        borderColor: "rgb(156, 39, 176)",
        backgroundColor: "rgba(156, 39, 176, 0.5)"
    },
]

const bulan = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Okt',
    'Nov',
    'Des',
]


export const getDownloadChartDataset = (data) => {


    let date = []
    const dataExtracted = {}
    const dataset = []

    for (const prop in data) {
        date.push(prop)
        for (const job in data[prop]) {
            if (dataExtracted[job] === undefined) {
                dataExtracted[job] = []
            }
            dataExtracted[job].push(data[prop][job])
        }
    }

    let index = 0
    for (const prop in dataExtracted) {
        dataset.push({
            label: capFirstLetter(prop),
            data: dataExtracted[prop],
            ...color[index]
        })
        index += 1
    }

    date = date.map(item => {
        item = item.split('-')

        return `${bulan[parseInt(item[1]) - 1]} ${item[0].slice(2, 4)}`
        // return `${parseInt(item[1])}`
    })


    return {
        labels: date,
        datasets: dataset
    }
}