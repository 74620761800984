import { Box, Text } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContextState } from '../../context/AuthContext'
import { removeLocalSession } from '../../services/session.service'
import Navbar from './components/Navbar'
import Form from './Form'

function AuthRoot() {
    const [auth, setAuth] = useContext(AuthContextState)
    const { pathname } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (pathname === '/auth/logout') {
            setAuth(null)
            removeLocalSession()
            navigate('/auth')
        }
        let timeout = setTimeout(() => {
            clearTimeout(timeout)
            if (auth !== null) navigate('/')
        }, 1000);
    }, [])

    return (
        <Fragment>

            <Navbar />
            <Box marginTop={10} mx='auto' textAlign='center'>
                <Text fontWeight={700} fontSize='20px' color='text'>Selamat Datang</Text>
                <Text fontSize='20px' color='text'>Silahkan login terlebih dahulu untuk menggunakan aplikasi</Text>
                <Box width='xl' bg='gray.100' mx='auto' mt={6} rounded='md' p={14} >
                    <Form />

                </Box>
            </Box>
        </Fragment>
    )
}

export default AuthRoot