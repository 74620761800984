import { LIST_SIDEBAR } from "../const/sidebar-list"


export const getBreadcrumbLink = (link) => {
    link = link.split('/')
    let result = []
    LIST_SIDEBAR.map((item, index) => {
        if (link[1] === item.link.split('/')[1]) {
            result.push({
                name: item.name,
                link: item.link
            })

            if (item.child) {
                item.child.map((itemChild, index) => {
                    if (link[2] === itemChild.link.split('/')[2]) {
                        result.push({
                            name: itemChild.name,
                            link: itemChild.link
                        })
                    }

                    return 0
                })
            }
        }

        return 0
    })

    // console.log(result)
    // console.log(lowestIndex)
    return result
}