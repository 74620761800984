import { Flex, Icon, Text } from '@chakra-ui/react'
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { BsInfoCircle, BsInfoCircleFill } from 'react-icons/bs'
import './DownloadChart.css'

function DownloadChart({ labels = [], datasets = [] }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom"
            },
            title: {
                display: true,
                text: "Publikasi Didownload"
            }
        }
    }

    // labels = ["January", "February", "March", "April", "May", "June", "July"]

    const data = {
        labels,
        // datasets: [
        //     {
        //         label: "Polisi",
        //         data: labels.map(() => parseInt(Math.random() * 50)),
        //         // borderColor: "rgb(255, 99, 132)",
        //         // backgroundColor: "rgba(255, 99, 132, 0.5)"
        //     },
        //     {
        //         label: "Dokter",
        //         data: labels.map(() => parseInt(Math.random() * 50)),
        //         // borderColor: "rgb(53, 162, 235)",
        //         // backgroundColor: "rgba(53, 162, 235, 0.5)"
        //     }
        // ]
        datasets
    }
    // console.log(labels, 'labels')
    // console.log(datasets, 'datasets')
    return (
        <div className="card">
            <div className="card-header">
                <Flex alignItems='center' justifyContent='space-between' >
                    <Text fontWeight={600} color='gray.500'>Grafik User Download</Text>
                    <Icon as={BsInfoCircleFill} color='gray.400' boxSize={4} />
                </Flex>
            </div>
            <div className="card-body">
                <Line options={options} data={data} />
            </div>
        </div>
    )
}

export default DownloadChart