import React from 'react'
import { Fragment } from 'react'

function CustomPageWrapper({ children }) {
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default CustomPageWrapper