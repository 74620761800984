import React, { useEffect } from 'react'
import { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { getKajian } from '../../../services/user.services';
import './selectautosuggest.css'


// const data = [
//     'budi', 'roti', 'budu'
// ]

function SelectAutoSuggest({ value = '', onChange = (value) => { }, placeholder = '', token = '' }) {
    const [suggestions, setSuggestions] = useState([]);
    const [localValue, setLocalValue] = useState('')
    const [data, setData] = useState([])

    const onChangeLocal = (_, { newValue }) => {
        // setLocalValue(newValue);
        onChange(newValue)
    };

    const getSuggestions = value => {
        return data.filter(suggestion =>
            suggestion.toLowerCase().includes(value.toLowerCase())
        );
    };

    const renderSuggestion = suggestion => <div>{suggestion}</div>;


    const inputProps = {
        placeholder,
        // value: localValue,
        value,
        onChange: onChangeLocal
    };

    useEffect(() => {
        getKajian({ token })
            .then(res => {
                const result = res.data.data
                console.log(result)
                setData(result.map(item => item.name))
            })
            .catch(e => { console.log(e) })
    }, [])

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => { setSuggestions(getSuggestions(value)) }}
            onSuggestionsClearRequested={() => { setSuggestions([]) }}
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    );
}

export default SelectAutoSuggest